<template>
  <div>
    <md-empty-state
      md-icon="cloud_queue"
      :md-label="lang.notFound.label"
      :md-description="lang.notFound.description">
      <router-link :to="{name: 'Home'}">
        <md-button class="md-primary md-raised md-filled">{{lang.notFound.buttonText}}</md-button>
      </router-link>
    </md-empty-state>
  </div>
</template>

<script>
import store from '@/store/index'
  export default {
    name: 'NotFound',
    computed: {
      lang() {
        return store.getters.getLanguage;
      }
    }
  }
</script>